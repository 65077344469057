import { Box, Button, Card, Flex, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text, useToast } from "@chakra-ui/react"
import { useDispatch, useSelector } from "react-redux";
import { krystelValuer } from "../../../lib/support";
import { useState } from "react";
import { switchToKrystel } from "../../../lib/api";
import { setUserProfile } from "../../../redux/userProfile/actions";

function Switch(){
    const user = useSelector((state) => state.userReducer);
    const dispatch = useDispatch()
    const toast = useToast()
    const [isLoading, setIsLoading] = useState(false)
    function generateArray(maxValue) {
        const array = [];
        for (let i = 1; i <= maxValue; i += 1) {
            array.push(i);
        }
        return array;
    }
    const items = generateArray(user.kollectibles.availableBalance/10000)
    console.log(items)
    const maxIndex = items.length - 1;
    const [selectedIndex, setSelectedIndex] = useState(0);

    const switchHandler = async() => {
        await switchToKrystel(items[selectedIndex]*10000)
        .then((response) => {
            setIsLoading(false)
            dispatch(setUserProfile({...user, kollectibles : {
                ...user.kollectibles, availableBalance : user.kollectibles.availableBalance - items[selectedIndex]*10000
            }}))
            toast({
                title: response.data.message,
                variant: 'subtle',
                status: 'success',
                duration: 3000,
                position: 'top'
            })
        })
        .catch((error) => {
            setIsLoading(false)
            toast({
                title: error.response.data.message,
                variant: 'subtle',
                status: 'info',
                duration: 3000,
                position: 'top',
            })
        })
    }

    return (
        <>
            <Card p={5} mb={5}>
                <Flex px={3} gap={2} justifyContent={"end"} mb={3}>
                    <Button borderRadius={"20px"} size={'sm'} fontSize={'2xs'}>{krystelValuer(user.kollectibles.availableBalance)}</Button>
                    <Button borderRadius={"20px"} size={'sm'} fontSize={'2xs'}>$ {(user.kollectibles.availableBalance/10000).toFixed(2)}</Button>
                    
                </Flex>
                <Flex px={3}>
                    <Text textAlign={'justify'} fontSize={'xs'}>If you wish to transfer your available balance to krystel, you can switch back here. An amount switched cannot be switched back to available balance.</Text>
                </Flex>
                <Box p={3}>
                    <Text color="blackAlpha.700" fontWeight="bold" mt={2}>
                        Selected Amount: $ {items[selectedIndex]}
                    </Text>
                    <Slider
                        isDisabled={user.kollectibles.availableBalance < 1}
                        step={1}
                        min={0}
                        max={maxIndex}
                        value={selectedIndex}
                        colorScheme="orange"
                        onChange={(val) => setSelectedIndex(val)} // Update index on change
                    >
                        <SliderTrack>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                    </Slider>
                    <Flex mt={2} justifyContent="end">
                        <Button size="sm"  isLoading={isLoading} onClick={switchHandler} isDisabled={user.kollectibles.availableBalance < 10000} fontSize="xs">Switch Back</Button>
                    </Flex>
                </Box>
            </Card>
        </>
    )
}

export default Switch